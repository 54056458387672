











































































import { Component, Vue, Prop } from 'vue-property-decorator'
import * as UserAction from '@/api/user'
import { sexEnumMap } from '@/config/enum/sex'
import { ElForm } from 'element-ui/types/form'
import { defaultUserData } from '@/api/default'

@Component
export default class AddUserComp extends Vue {
  @Prop() visible!: boolean
  private form: User.IUser = {
    ...defaultUserData
  }

  private rules: any = {
    u_name: [
      { required: true, message: '请输入姓名', trigger: 'blur' }
    ],
    u_class: [
      { required: true, message: '请输入部门', trigger: 'blur' }
    ],
    u_number: [
      { required: true, message: '请输入工号', trigger: 'blur' }
    ],
    u_birthday: [
      { required: true, message: '请输入出生日期', trigger: 'blur' }
    ],
    u_nation: [
      { required: true, message: '请输入民族', trigger: 'blur' }
    ],
    u_sex: [
      { required: true, message: '请选择性别', trigger: 'change' }
    ],
    u_email: [
      { type: 'email', required: true, message: '请输入邮箱地址', trigger: 'blur' }
    ],
    u_tel: [
      { required: true, message: '请输入手机号', trigger: 'blur' }
    ],
    u_identity: [
      { required: true, message: '请输入身份证号', trigger: 'blur' }
    ]
  }
  private sexEnumMap = sexEnumMap

  get dialogVisible () {
    return this.visible
  }
  set dialogVisible (val) {
    this.$emit('update:visible', val)
  }

  async submit () {
    const ref = this.$refs.addUser as ElForm
    ref.validate(async valid => {
      if (!valid) return
      const res = await UserAction.addUser({
        ...this.form
      })
      if (res.success) {
        this.dialogVisible = false
        this.$message.success('新增用户成功！')
        ref.resetFields()
        this.$emit('submit')
      } else {
        this.$message.error('新增用户失败')
      }
    })
  }
}

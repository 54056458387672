// 默认问卷数据
export const defaultNaireData = {
  title: '',
  intro: '',
  deadline: 0,
  status: 0,
  options: '',
  topic: []
}

// 默认用户数据
export const defaultUserData = {
  u_number: '',
  u_identity: '',
  u_name: '',
  u_nation: '',
  u_birthday: '',
  u_sex: '',
  u_class: '',
  u_email: '',
  u_tel: '',
  u_phone: '',
  token: '',
  avatar: '',
  idno_up: '',
  idno_down: '',
  work_time: '',
  open_id: '',
  address: ''
}

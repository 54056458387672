




























































import { Component, Vue } from 'vue-property-decorator'
import * as UserAction from '@/api/user'
import AddUser from '@/views/user/components/AddUser.vue'
import EditUser from '@/views/user/components/EditUser.vue'
import { defaultUserData } from '@/api/default'

interface ISearchOption {
  value: string,
  label: string
}

@Component({
  components: {
    AddUser,
    EditUser
  }
})
export default class extends Vue {
  private loading: boolean = false
  private searchParams: {
    keyword: string,
    value: string
  } = {
    keyword: 'u_name',
    value: ''
  }
  public searchOptions:ISearchOption[] = [
    { value: 'u_name', label: '姓名' },
    { value: 'u_identity', label: '身份证号' },
    { value: 'u_phone', label: '手机号' },
    { value: 'work_time', label: '从业时间' },
    { value: 'address', label: '家庭住址' }
  ]
  private editModel: User.IUser = {
    ...defaultUserData
  }
  private addUserVisible: boolean = false
  private editUserVisible: boolean = false
  private selectContent: User.IUser[] = []
  private pageParams: {
    current: number,
    page_size: number
  } = {
    current: 1,
    page_size: 10
  }
  private total: number = 0
  public list: User.IUser[] = []

  get uploadUrl () {
    return `${process.env.VUE_APP_BASE_API}/user/upload`
  }

  onChangePage (val: number) {
    this.pageParams.current = val
    this.fetchListData()
  }

  onSelectionChange (val: User.IUser[]) {
    this.selectContent = val
  }

  onUploadExceeded () {
    this.$message.warning('仅支持单个单文件导入')
  }

  onUploadSuccess (response: any) {
    if (response.err === 0) {
      this.$message.success(response.msg)
      this.fetchListData()
    } else {
      this.$message.error('上传出错')
    }
  }

  onUploadError () {
    this.$message.error('上传出错')
  }

  handleEdit (row: User.IUser) {
    this.editModel = row
    this.editUserVisible = true
  }

  handleDelete (row: User.IUser) {
    this.$confirm('确认删除此用户吗？', '删除', {
      type: 'warning'
    })
      .then(() => {
        this.delUser(row.u_id || '')
      })
      .catch(() => {})
  }

  public addUser () {
    this.addUserVisible = true
  }

  private async delUser (nIds: string) {
    const res = await UserAction.del({
      u_id: nIds
    })
    if (res.success) {
      this.$message.success(`成功删除${res.data}个用户`)
      this.fetchListData()
    } else {
      this.$message.error(res.msg)
    }
  }

  public batchDelete () {
    this.$confirm('您确认删除这几条内容吗？未选择任何项，则删除全部数据', '批量删除', {
      type: 'warning'
    })
      .then(async () => {
        const rowIds = this.selectContent.map(({ u_id: id }) => id).join(',')
        this.delUser(rowIds)
      })
      .catch(() => {})
  }

  public clearAll () {
    this.$confirm('您确认删除全部内容吗？', '一键清空', {
      type: 'warning'
    })
      .then(async () => {
        const res = await UserAction.clear()
        if (res.success) {
          this.$message.success('清空用户成功')
          this.fetchListData()
        } else {
          this.$message.error(res.msg)
        }
      })
      .catch(() => {})
  }

  public async fetchListData () {
    this.loading = true
    const res = await UserAction.list({
      ...this.searchParams,
      ...this.pageParams
    })
    this.loading = false
    if (res.success) {
      this.list = res.data.list
      this.total = res.data.total
    } else {
      this.$message.error(res.msg)
    }
  }

  mounted () {
    this.fetchListData()
  }
}
